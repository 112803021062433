import { $, useAppSelector } from '@/common';
import { getFilteredEvents, getNextEvents, selectEvents, selectHasMoreEvents, selectIsEventsLoading } from '@/stores/events';
import { NavLink, useParams } from 'react-router-dom';
import { store } from '@/stores';
import { format } from 'date-fns/format';
import { parseISO } from 'date-fns/parseISO';
import { Event, EventType } from '@/stores/types';
import InfiniteScroll from '@/modules/events/components/infiniteScroll';
import EventFilterForm, { EventFilter } from './components/eventFilter/EventFilterForm';
import { selectEventTags, selectEventTypes } from '@/stores/eventMetadata';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@/types';

const concatenateTags = (event: Event) => {
  const commaSeparatedTags = event.tags ? event.tags.map((tag) => tag.tagName).join(', ') : '';
  return commaSeparatedTags;
};

const Events = (props: { eventType: EventType; placeholder: string }): JSX.Element => {
  const { eventType, placeholder } = props;
  const { siteId } = useParams();
  const events = useAppSelector(selectEvents(eventType));
  const isLoading = useAppSelector(selectIsEventsLoading);
  const isHasMoreEvents = useAppSelector(selectHasMoreEvents(eventType));
  const eventTypes = useAppSelector(selectEventTypes);
  const eventTags = useAppSelector(selectEventTags);
  const { [FeatureFlags.eyecueMonitorUseEventFilters]: eyecueMonitorUseEventFilters = false } = useFlags();

  const handleFilterSubmit = (filterData: EventFilter) => {
    if (siteId) {
      store.dispatch(getFilteredEvents({ siteId, eventType, eventFilter: filterData || {} }));
    }
  };

  const tagNames = eventTags.map((eventTag) => eventTag.tagName);

  return (
    <>
      {eyecueMonitorUseEventFilters === true ? (
        <div className={$('pb-4')}>
          <EventFilterForm
            eventType={eventType}
            eventTypes={eventTypes.map((eventType) => eventType.type)}
            tagNames={tagNames}
            onFilterSubmit={handleFilterSubmit}
          />
        </div>
      ) : (
        <></>
      )}
      {/* 142px = page header + filter buttons + padding/margin */}
      <div style={{ height: `calc(-142px + 100vh)` }} className={$('overflow-y-auto')}>
        <InfiniteScroll isLoading={isLoading} hasMore={isHasMoreEvents} next={() => store.dispatch(getNextEvents({ siteId: siteId ?? '', eventType }))}>
          {!isLoading && events.length === 0 ? (
            <div className={$('grid min-h-full place-items-center text-emphasis-secondary')}>
              <span>{placeholder}</span>
            </div>
          ) : (
            <ul role='list' className={$('grid grid-cols-1 gap-y-2')}>
              {events.map((event, index) => (
                <li
                  key={index}
                  className={$(
                    'items-center rounded-md border border-divider-muted bg-white text-emphasis-secondary',
                    'hover:bg-button-primary-hover hover:text-white hover:border-transparent',
                  )}
                >
                  <div className={$('mt-1.5 mb-1.5 ml-2')}>
                    <NavLink to={event.eventId} className={$('cursor-pointer')}>
                      <div className={$('flex flex-row align-middle space-x-4 text-base font-medium')}>
                        <div className={$('leading-6 w-1/4 text-left')}>{event.eventType}</div>
                        <div className={$('leading-6 w-1/4 text-left')}>
                          <span>{format(parseISO(event.thresholdEventTimestamp || event.startTime), 'dd-MM-yy')}</span>{' '}
                          <span className={$('pl-4')}>{format(parseISO(event.thresholdEventTimestamp || event.startTime), 'HH:mm:ss')}</span>
                        </div>
                        <div className={$('w-2/4 text-left pr-2')}>{concatenateTags(event)}</div>
                      </div>
                    </NavLink>
                  </div>

                  <div className={$('flex shrink-0 items-center gap-x-4')}>{/* <span>Delete</span> */}</div>
                </li>
              ))}
            </ul>
          )}
        </InfiniteScroll>
      </div>
    </>
  );
};

export default Events;
