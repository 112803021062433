import { $ } from '@/common';
import { type FieldValues, useForm, Controller } from 'react-hook-form';
import EventTypeSelector from './EventTypeSelector';
import EventTagSearchBar from './EventTagSearchBar';
import DateTimePicker from './DateTimePicker';
import { useState } from 'react';

export interface FormProps extends React.FormHTMLAttributes<HTMLFormElement> {
  children?: JSX.Element | JSX.Element[];
  isDirty?: boolean;
  isSubmitting?: boolean;
  onCancel?: () => void;
  eventType?: string;
  eventTypes: string[];
  tagNames: string[];
  onFilterSubmit: (filter: EventFilter) => void;
}

export interface EventFilter {
  startDateTime?: Date | null;
  endDateTime?: Date | null;
  startTime?: Date | null;
  endTime?: Date | null;
  eventTypeFilter?: string;
  eventTagsFilter?: string[];
}

const defaultFilterValues: EventFilter = {
  startDateTime: null,
  endDateTime: null,
  startTime: null,
  endTime: null,
  eventTypeFilter: undefined,
  eventTagsFilter: undefined,
};

const EventFilterForm = ({ eventType, onFilterSubmit, eventTypes, tagNames }: FormProps): JSX.Element => {
  const { control, handleSubmit, reset } = useForm<EventFilter>({
    defaultValues: defaultFilterValues,
  });
  const [tagsKey, setTagsKey] = useState<number>(1);

  const typeOptions = eventTypes.map((eventType, index) => {
    return {
      value: eventType,
      label: eventType,
    };
  });

  const onSubmit = async (data: FieldValues): Promise<void> => {
    onFilterSubmit(data as EventFilter);
  };

  const handleReset = (): void => {
    setTagsKey(tagsKey + 1);
    reset();
  };

  const gridClasses = 'grid grid-cols-7 gap-x-12';

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={$('flex flex-row-reverse justify-end gap-x-12 mt-2')}>
        <div className={$('col-span-2')}>
          <button
            type='submit'
            disabled={false}
            className={$('h-10 bg-button-primary rounded-md px-3 py-2 text-base font-semibold text-white shadow-sm focus-visible:outline-offset-2 min-w-24')}
          >
            Filter
          </button>
        </div>
        <div className={$('col-span-2')}>
          <button
            type='submit'
            disabled={false}
            className={$('h-10 bg-button-secondary rounded-md px-3 py-2 text-base font-semibold text-white shadow-sm focus-visible:outline-offset-2 min-w-24')}
            onClick={() => {
              handleReset();
            }}
          >
            Clear Filters
          </button>
        </div>
      </div>
      <div className={$(gridClasses)}>
        <div className={$('col-span-2 flex')}>
          <Controller
            name='startDateTime'
            control={control}
            render={({ field }) => (
              <DateTimePicker
                label='Start date and time'
                {...field}
                value={field.value ?? null} // Ensure null is passed when undefined
              />
            )}
          />
        </div>
        <div className={$('col-span-2 flex')}>
          <Controller
            name='endDateTime'
            control={control}
            render={({ field }) => (
              <DateTimePicker
                label='End date and time'
                {...field}
                value={field.value ?? null} // Ensure null is passed when undefined
              />
            )}
          />
        </div>
        <div className={$('col-span-3 h-full flex flex-col')}>
          <div className={$('mt-auto')}>
            <Controller
              name='eventTypeFilter'
              control={control}
              render={({ field }) => <EventTypeSelector label='Select event type' options={typeOptions} {...field} />}
            />
          </div>
        </div>
      </div>
      {eventType === 'ARCHIVE' ? (
        <div key={tagsKey} className={$('mt-2 mb-5')}>
          <Controller name='eventTagsFilter' control={control} render={({ field }) => <EventTagSearchBar label='Event tags' options={tagNames} {...field} />} />
        </div>
      ) : (
        <></>
      )}
    </form>
  );
};

export default EventFilterForm;
