import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { type DateTimePickerProps, DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { $ } from '@/common';
import { type Ref } from 'react';
import React from 'react';

const DateTimePicker = (props: DateTimePickerProps<Date>, ref: Ref<HTMLInputElement>): JSX.Element => {
  const { label, onChange, value } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className={$('items-center w-full')}>
        <label className={$('block text-xs font-normal leading-6 text-[#ADA7A7] whitespace-nowrap min-w-24')}>{label}</label>
        <MuiDateTimePicker
          inputRef={ref}
          format='dd/MM/yy HH:mm'
          onChange={onChange}
          value={value}
          slotProps={{
            textField: {
              size: 'small',
              style: { width: '100%' },
            },
          }}
        />
      </div>
    </LocalizationProvider>
  );
};

export default React.forwardRef(DateTimePicker);
