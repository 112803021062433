import { type TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, type RootState } from '@/stores';
import classNames from 'classnames';
import reduce from 'lodash/reduce';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const $ = (...classes: classNames.ArgumentArray): string => {
  const joinedClasses = classNames(...classes);
  return joinedClasses;
};

// This will return true for following cases: undefined, null, "", {}, []
export const isEmpty = (value: any): boolean => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'string' && value.trim() === '') ||
    (typeof value === 'object' && Object.keys(value).length === 0)
  );
};

export const getDirtyFormData = <TData extends Record<keyof TDirtyItems, unknown>, TDirtyItems extends Record<string, unknown>>(
  data: TData,
  dirtyFields: TDirtyItems,
): Partial<TData> => {
  return reduce(
    dirtyFields,
    (dirtyData, value, name) => {
      if (value === true) {
        return { ...dirtyData, [name]: data[name] };
      } else if (typeof value === 'object' || Array.isArray(value)) {
        // Recursively get dirty form data
        return {
          ...dirtyData,
          [name]: getDirtyFormData(data[name] as TData, dirtyFields[name] as TDirtyItems),
        };
      }

      return dirtyData;
    },
    {},
  );
};

const COMPOUND_CLIENT_IDS = ['stb-nzl', 'cul-usa', 'zmb-aus', 'tim-can'];
// Any new 'two part' customer accounts will have to be added here so that parsing clientIds
// from siteIds does not incorrectly return 'stb' instead of 'stb-nzl'

export const parseSiteId = (siteId: string): { clientId: string; siteIdDigits: string } => {
  const parts = siteId.split('-');

  if (parts.length < 4) {
    window.logger.warn(`Invalid siteId ${siteId}`);

    return {
      clientId: '',
      siteIdDigits: '',
    };
  }

  const clientId = COMPOUND_CLIENT_IDS.find((id) => siteId.includes(id));
  if (clientId !== undefined && clientId !== null) {
    return {
      clientId,
      siteIdDigits: parts[3],
    };
  } else {
    return {
      clientId: parts[1],
      siteIdDigits: parts[3],
    };
  }
};
