import DialogWrapper, { CloseButton, ModalContent, ModalHeader, ModalTitle } from '@/modules/components/dialogWrapper';
import { TextButton } from '@/modules/components/buttons';
import { $ } from '@/common';
import createConfirmDialog, { type ConfirmDialogProps } from '@/modules/components/confirmDialog';

const TagSavedOptionConfirm = (props: ConfirmDialogProps): JSX.Element => {
  const { reject, confirm, close, ...rest } = props;

  return (
    <DialogWrapper {...rest}>
      <ModalHeader>
        <ModalTitle>Event saved</ModalTitle>
        <div className={$('flex flex-1 justify-end')}>
          <CloseButton
            onClick={(e: any) => {
              e.preventDefault();
              close();
            }}
          />
        </div>
      </ModalHeader>
      <ModalContent>
        <div className={$('grid grid-cols-1 gap-y-8 min-w-96')}>
          <span className={$('flex items-center text-center justify-center')}>This event has been saved to Saved Events</span>
          <TextButton
            onClick={(e) => {
              confirm({ to: 'events' });
            }}
          >
            Review another event
          </TextButton>
          <TextButton
            onClick={(e) => {
              confirm({ to: 'archive' });
            }}
          >
            Go to Saved Events
          </TextButton>
        </div>
      </ModalContent>
    </DialogWrapper>
  );
};

export default createConfirmDialog(TagSavedOptionConfirm);
