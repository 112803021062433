import { $, getDirtyFormData, useAppDispatch, useAppSelector } from '@/common';
import { ContainedButton, TextButton } from '@/modules/components/buttons';
import { Controller, type SubmitHandler, useForm } from 'react-hook-form';
import type { TagClipFormModel, Video } from '@/stores/types';
import { selectEvent, tagAndArchiveEvent } from '@/stores/events';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { SpinnerIcon } from '@/common/assets/icons';
import tagSavedOptionConfirm from '@/modules/clips/tagClips/tagForm/TagSavedOptionConfirm';
import TagSelect from '@/modules/clips/tagClips/tagForm/TagSelect';
import { useJitsu } from '@jitsu/jitsu-react';
import { getEventMetadata, selectEventTags } from '@/stores/eventMetadata';

interface TagFormProps {
  selectedVideo: Video[];
}

const TagForm = (props: TagFormProps): JSX.Element => {
  const { selectedVideo } = props;
  const { siteId = '', eventId = '', clientId = '' } = useParams();
  const availableTags = useAppSelector(selectEventTags);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { analytics } = useJitsu();

  const event = useAppSelector(selectEvent(eventId));

  const {
    register,
    reset,
    handleSubmit,
    formState: { dirtyFields, isDirty, isSubmitting },
    control,
  } = useForm<TagClipFormModel>({
    defaultValues: {
      tagNames: event?.tags?.map((tag) => tag.tagName) ?? [],
      userNotes: event?.userNotes ?? '',
    },
  });

  useEffect(() => {
    // Reset form
    reset({
      tagNames: event?.tags?.map((tag) => tag.tagName) ?? [],
      userNotes: event?.userNotes ?? '',
    });
  }, [event, availableTags]);

  const onSubmit: SubmitHandler<Record<string, any>> = async (data: Record<string, any>) => {
    const dirtyData = getDirtyFormData(data, dirtyFields);
    const { tagNames } = data;

    try {
      event?.reviewed
        ? analytics.track('monitor_archivedEventUpdated', { event: { id: event?.eventId }, updatedFields: dirtyData })
        : analytics.track('monitor_eventArchived', { event: { id: event?.eventId } });
      // Tag and archive event
      // When user just add tags and not modify the existing tags, the 'tagNames' field will be excluded from the payload
      // Ensure that the 'tagNames' field is included in the payload to maintain the existing tags
      await dispatch(tagAndArchiveEvent({ payload: { ...dirtyData, tagNames, siteId, eventId, archivedVideos: selectedVideo } }));

      // Update available tags
      await dispatch(getEventMetadata({ siteId })); // TODO replace with getTags from event metadata

      // Prompt user with options
      const { to } = await tagSavedOptionConfirm();
      navigate(`/admin/${clientId}/${siteId}/monitor/${to}`);
    } catch (error: any) {
      window.logger.error(error);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller name='tagNames' control={control} render={({ field }) => <TagSelect {...field} />} />
        <div className={$('pt-16')}>
          <textarea
            rows={4}
            id='note'
            className={$(
              'block w-full p-2 rounded-md border-0 ring-1 ring-inset ring-input-muted focus:outline-none focus:ring-2 focus:border-transparent focus:ring-input-focus',
            )}
            defaultValue={''}
            placeholder='Note...'
            maxLength={255}
            {...register('userNotes')}
          />
        </div>
        <div className={$('mt-6 flex items-center justify-end gap-x-6')}>
          <TextButton
            onClick={(e) => {
              e.preventDefault();
              // Discard changes and go back to events list
              reset();
              navigate('../..');
            }}
            disabled={isSubmitting}
          >
            Cancel
          </TextButton>
          <ContainedButton type='submit' disabled={isSubmitting || !isDirty} className='min-w-fit'>
            {isSubmitting ? <SpinnerIcon className='w-6 h-6' /> : event?.reviewed ? 'Save' : 'Save to archive'}
          </ContainedButton>
        </div>
      </form>
    </>
  );
};

export default TagForm;
